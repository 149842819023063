<template>
  <v-theme-provider dark>
    <base-section id="contact-us" class="primary" space="40">
      <v-container>
        <v-row justify="center">
          <v-col cols="10">
            <base-title
              :title="$ml.get('private_territory')"
              class="text-center"
            />
            <base-body space="0" class="text-center">
              <span class="white--text">
                {{$ml.get('private_territory_text')}}
              </span>
            </base-body>
          </v-col>
        </v-row>
        <v-row class="mt-5" justify="center">
          <v-col cols="12" class="col-md-6 text-center">
            <v-btn color="white" large outlined v-on:click="authorizeWK">
               {{$ml.get('auth_waves_keeper')}}
            </v-btn>
          </v-col>
          <v-col cols="12" class="col-md-6 text-center">
            <v-dialog transition="dialog-top-transition" max-width="600">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="white" large outlined v-bind="attrs" v-on="on">
                  {{$ml.get('auth_wallet_number')}}
                </v-btn>
              </template>
              <template v-slot:default="dialog">
                <v-card>
                  <v-toolbar color="primary" dark
                    >{{$ml.get('please_input_address')}}</v-toolbar
                  >
                  <v-card-text class="mt-6 pb-0">
                    <v-text-field
                      placeholder="3GAMvhkPeV3euPuN29KR6vAZbgcF8yLu75C"
                      outlined
                      v-model="address"
                    ></v-text-field>
                  </v-card-text>
                  <v-card-actions class="justify-end">
                    <v-btn
                      color="green"
                      text
                      @click="
                        dialog.value = false;
                        authorizeByAddress(address);
                      "
                      >OK</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row justify="center" v-if="accountLoaded">
          <v-col cols="10">
            <v-card color="#007ab9" dark class="mt-5 text-center">
              <v-card-text class="headline text-center white--text"
                >Вы успешно авторизовались на сайте</v-card-text
              >
              <v-card-subtitle>{{ address }}</v-card-subtitle>

              <v-card-actions class="justify-end">
                <v-btn text v-on:click="accountLoaded = false"> OK </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
import { verifyAuthData, libs } from "@waves/waves-transactions";
import axios from "axios";
import { ebus } from "../../main.js"; //шина событий

export default {
  name: "SectionAuthorization",
  components: {},
  data() {
    return {
      acc: "",
      accountLoaded: false,
      assetInfo: "",
      address: "",
      amount: 0,
    };
  },
  mounted() {
    //this.authorizeWK();
  },

  methods: {
    async authorizeWK() {
      // Example
      const authValidate = (data, signature, publicKey, chainId) => {
        const chain =
          typeof chainId === "string" ? chainId : String.fromCharCode(chainId);
        const address = libs.crypto.address({ publicKey }, chain);
        return verifyAuthData({ publicKey, address, signature }, data);
      };

      let p1 = await WavesKeeper.auth({ data: "123" });
      console.log("p1", p1);
      this.acc = p1;

      //var res = authValidate(this.account, {host: p1.host, data: "123", }); // true
      //console.log("результат авторизации Waves Keeper: ", res);

      this.address = this.acc.address;
      this.checkAddress(this.address);
    },
    authorizeByAddress(addr) {
      //проверяем существует ли адрес
      this.checkAddress(addr);
    },
    getAssetInfo() {
      axios
        .get(
          "https://nodes.wavesnodes.com/assets/details/6WbyYJvfvdUpP4bTWVUk6mMdzGPYXngxQdtGk3vZGLvK"
        )
        .then((response) => {
          console.log("prices usdt:", response.data);
          this.assetInfo = response.data;
        });
    },
    checkAddress: function (addr) {
      axios
        .get("https://nodes.wavesnodes.com/addresses/balance/" + addr)
        .then((response) => {
          if (response.status === 200) {
            this.accountLoaded = true;
            //отправляем в шину событие авторизации юзера
            if (this.accountLoaded) {
              ebus.$emit("userauthorized", addr);
            }
          } else {
            this.accountLoaded = false;
          }
        });
    },
  },
};
</script>
